.flex{
    display:flex;
    flex-wrap:wrap;
}

.flex1{
    flex:1;
}

.justify-center{
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-end{
    justify-content:flex-end;
}
.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center{
    align-items: center;
}

.flex-center{
    align-items:center;
    justify-content: center;
}

.f-column{
    flex-direction: column;
}
