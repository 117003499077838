.links{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    div{
        font-size: .26rem;
        font-weight: 900;
        cursor: pointer;
        color: #EBEBEB;
        margin:0 .93rem;
    }
}

.header{
    background-color: #191919;
}
