@font-face {
    font-family: "AlegreyaSans-Black";
    src :url('../fonts/AlegreyaSans-Black-2.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "AlegreyaSans-BlackItalic";
    src :url('../fonts/AlegreyaSans-BlackItalic-3.ttf');
    font-weight: normal;
    font-style: normal;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}


.ffab{
    font-family: "AlegreyaSans-Black";
}
.ffai{
    font-family: "AlegreyaSans-BlackItalic";
}

.logo{
    width: 1.9rem;
}


img{
    max-width:100%;
}



.page{
    background:#191919;
}

body,html{
    min-width:1200px;
}

.myreply{
    width: 100%;
    height: 100vh;
    background: url('../images/reply.png') no-repeat;
    background-size: cover;
  }
  .reply{
    width: 6.4rem;
    margin: auto;
    height: 600px;
    padding: .2rem .6rem;
    background: #FFFFFF;
    box-shadow: 0px 17px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    border: 5px solid rgba(255, 255, 255, 0.35);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-size: 0.14rem;
    div{
      margin-top: 20px;
      display: flex;
      span{
        &:first-child{
          display: inline-block;
          width: 84px;
        }
        &:last-child{
          flex: 1;
        }
      }
    }
    .reflex{

    }
    textarea{
      padding: .1rem;
      line-height: 1.5;
      border: none;
      width: 80%;
      height: 1.8rem;
      background: #F6F6F6;
      border-radius: 10px;
    }
    .sub{
      padding:8px 12px;
      color: #fff;
      background-color: #000000;
      border: none;
      margin-left: 80px;
      margin-top: 20px;
      border-radius: 4px;
    }
    .label{
      color: rgba(51, 51, 51, 0.5);
    }
    .l1{
      font-size: 0.18rem;
      color: #222;
      font-weight: 600;
    }
    .l2{
      font-size: 0.14rem;
      background: #F6F6F6;
      border-radius: 10px;
      padding: 0.1rem 0.16rem;
      line-height: 1.5;
    }
    .toast{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      padding: 0.3rem 0.2rem;

    }
  }
