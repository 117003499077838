.footer{
    width:100%;
    background-image:url('../../assets/images/footer-bg.png');
    background-size:cover;
    background-color: #191919;
    .line{
        width: 3px;
        height: .2rem;
        background:#fff;
        margin:0 24px;
        border-radius:3px;
    }
    .page-links{
        margin-top:-.25rem;
        text-align: center;
        a{
            width: 70px;
            margin:0 8px;
        }
    }
    .copyright{
        margin-top: .38rem;
        font-size: .16rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: .24rem;
    }
}
