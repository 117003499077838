html {
    --primary-color: #e57c58;
    font-size: 100px;
    // scroll-behavior: smooth;
    // @media (max-width: 750px) {
    //     font-size: 13.33vw;
    // }
    // @media (min-width: 1000px) {
    //     font-size:90px;
    // }
    // @media (min-width: 1680px) {
    //     font-size: 100px;
    // }
    // @media(min-width:2100px){
    //     font-size:110px;
    // }
}
.pb180 {
    padding-bottom: 1.8rem;
}
.mv__item{
    padding-bottom:51%;
    overflow: hidden;
    position:relative;
    img{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
    }
}
.mt160{
    margin-top: 1.6rem;
}
@for $i from 1 through 140 {
    .pl#{$i} {
        padding-left: #{$i/100}rem;
    }
    .w#{$i} {
        width: #{$i/100}rem;
    }
    .h#{$i} {
        height: #{$i/100}rem;
    }
    .pr#{$i} {
        padding-right: #{$i/100}rem;
    }
    .pt#{$i} {
        padding-top: #{$i/100}rem;
    }
    .pb#{$i} {
        padding-bottom: #{$i/100}rem;
    }
    .ml#{$i} {
        margin-left: #{$i/100}rem;
    }
    .mt#{$i} {
        margin-top: #{$i/100}rem;
    }
    .mr#{$i} {
        margin-right: #{$i/100}rem;
    }
    .mb#{$i} {
        margin-bottom: #{$i/100}rem;
    }
    .fz#{$i} {
        font-size: #{$i/100}rem;
    }
    .lh#{$i} {
        line-height: #{$i/100}rem;
    }
    .rad#{$i} {
        border-radius: #{$i/100}rem;
    }
    .top#{$i} {
        top: #{$i/100}rem;
    }
    .bottom#{$i} {
        bottom: #{$i/100}rem;
    }
}
.w1200 {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.left0 {
    left: 0;
}
.top0 {
    top: 0;
}
.opa7 {
    opacity: 0.7;
}
.pad {
    padding: 0 2.6%;
}

.pad-lg {
    padding-left: 8%;
    padding-right: 8%;
}

.w253 {
    width: 2.53rem;
}
.ccc {
    color: #ccc;
}

.primaryText,.text-primary {
    color: var(--primary-color) !important;
}

.hover-primary {
    &:hover {
        color: var(--primary-color);
    }
}

.fw400 {
    font-weight: 400;
}
.fw500 {
    font-weight: 500;
}

.fw400 {
    font-weight: 600;
}

.fw800,
.bold,
.fw600 {
    font-weight: 800;
}
.block-center {
    margin-left: auto;
    margin-right: auto;
}

.cursor {
    cursor: pointer;
}

.full-width {
    width: 100%;
}

.window-height {
    height: 100vh;
}

.window-width {
    width: 100%;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
.fixed {
    position: fixed;
    z-index: 1100;
}

.oh {
    overflow: hidden;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tac {
    text-align: center;
}

.sizing {
    box-sizing: border-box;
}

.text-white {
    color: #fff;
}
.bg-white {
    background: #fff;
}

.text-black {
    color: #000;
}

.text-black2 {
    color: #212121;
}

.text-grey {
    color: #888;
}

.tar {
    text-align: right;
}

.wrap {
    div {
        margin-top: 0.2rem;
        margin-bottom: 0;
    }
    span {
        font-weight: bold;
    }
}
.banner-text {
    width: 940px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.red {
    color: red;
    display: inline-block !important;
}

.grey {
    color: #ebebeb;
}

.backTop {
    position: fixed;
    right: 0.35rem;
    bottom: 0.9rem;
    z-index: 1000;
    cursor: pointer;
}

.swiper-pagination {
    bottom: 0.46rem !important;
    .swiper-pagination-bullet {
        width: 0.42rem;
        height: 0.42rem;
        position: relative;
        opacity: 1 !important;
        background-color: transparent !important;
        &.swiper-pagination-bullet-active {
            background-image: url("../images/current.png");
            background-size: cover;

            &:after {
                display: none;
            }
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 0.22rem;
            height: 0.22rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #ebebeb;
            display: block;
            opacity: 0.5;
            border-radius: 50%;
        }
    }
}

.game {
    background-image: url("../images/bg-game.jpeg");
    background-size: cover;
    background-position: center center;
    &.contact {
        background-image: url("../images/bg-contact.jpeg");
        background-size: cover;
        .game-title{
            padding-bottom: 0;
        }
    }
    &.about {
        background: none;
        .game-title {
            padding-bottom: 0;
        }
    }
    .game-title {
        width: 6.72rem;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 7.5rem;
    }
}
.about {
    .message {
        margin-left: 1.58rem;
        margin-right: 1.58rem;
        position: relative;
        margin-top: 1.4rem;
        position:relative;
        .message-img {
            width: 41.89%;
            position: absolute;
            left:0;
            top:0;
            z-index: 10;
        }
        .message-cont {
            float:right;
            // position: absolute;
            padding-left: 13.5%;
            // right: 0;
            width: 69.32%;

            // top: 0.6rem;
            margin-top:.6rem;
            box-sizing: border-box;
            padding-top: 0.6rem;
            border-radius: 5px;
            background-color: #262626;
            .arrow-img {
                width: 0.62rem;
                display: block;
            }
            .text {
                width: 74.19%;
                font-size: 0.16rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ebebeb;
                line-height: 0.34rem;
                margin-top: 0.2rem;
            }
        }
    }
    .company {
        // padding-top: 2.2rem;
        .title {
            display: block;
            width: 4.38rem;
            margin-top:1.6rem;
        }
    }
}
.benefit {
    padding-top: 0.25rem;
    .benefit-item {
        width: 3.78rem;
        position: relative;
        background: linear-gradient(
            180deg,
            #2d2d2d 0%,
            rgba(32, 32, 32, 0.5) 100%
        );
        border-radius: 10px;
        height: 3.6rem;
        margin-top: 1.07rem;
        .intro {
            height: 1.07rem;
            position: absolute;
            bottom: 0;
            left: 0;
            padding-right: 0.2rem;
            width: 100%;
            box-sizing: border-box;
            font-size: 0.22rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ebebeb;
            line-height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .top-box {
            .num {
                position: absolute;
                left: 0.48rem;
                top: 0.9rem;
                width: 0.6rem;
            }
            .img {
                position: absolute;
                width: 2.22rem;
                top: -0.44rem;
                right: 0;
            }
        }
    }
}

.contact-msg {
    margin-top: 1.6rem;
    padding-bottom: 2.65rem;
    .flex-item {
        width: 5.8rem;
        height: 4.9rem;
        text-align: center;
        // margin: 0 .2rem;
        padding-top:2.38rem;
        box-sizing: border-box;
        &.address {
            background-image: url("../images/address.png");
            background-size: cover;
            background-position:center center;
        }
        &.email {
            background-image: url("../images/email.png");
            background-size: cover;
            background-position:center center;
        }
        .title{
        font-size: .3rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #EBEBEB;
        line-height: .5rem;
        }
        .cont{
            width: 4.6rem;
        font-size: .2rem;
        margin-left: auto;
        margin-right: auto;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-top:.2rem;
        color: #A3A3A3;
        line-height: .34rem;
        }
    }
}


#game,#about,#contact{
    width: 100%;
    height: 0;
    position:relative;
    top:-92px;
}
